// extracted by mini-css-extract-plugin
export var answerContainer = "training-module--answerContainer--zOLl8";
export var answerKorean = "training-module--answerKorean--+ntin";
export var buttons = "training-module--buttons--uI9Tx";
export var conjugation = "training-module--conjugation--4nrf5";
export var dictionaryForm = "training-module--dictionaryForm--MIIGG";
export var disabled = "training-module--disabled--TcMPk";
export var formality = "training-module--formality--Z8ddW";
export var guessContainer = "training-module--guessContainer--WOaiE";
export var options = "training-module--options--LZ2RJ";
export var tag = "training-module--tag--2DCtp";
export var tense = "training-module--tense--E55ai";
export var wordToGuess = "training-module--wordToGuess--KNEuA";