import { Button, FormControl, Input, InputLabel } from '@mui/material';
import * as React from 'react';
import SettingsContext, { Settings, SettingsProvider } from '../components/settings/settings.context';
import TopBar from '../components/topbar/topbar';
import { ConjugationEngineRule, Conjugations, generateConjugations } from '../conjugation-engine/conjugation-engine';
import { Tenses, verbs } from '../data/conjugation';
import { Verb, Word } from '../interfaces/verb';
import {
  answerContainer,
  answerKorean,
  buttons,
  conjugation,
  dictionaryForm,
  formality,
  guessContainer,
  options,
  tag,
  tense,
  wordToGuess,
} from './training.module.scss';

const STORAGE_KEY = 'conjugationSettings';
const hasWindow = typeof window !== 'undefined';

const TrainingPage = () => {
  const randomWordGenerator = (): Verb => {
    const length = verbs.length;
    const random = Math.floor(Math.random() * length);
    return verbs[random % length];
  };

  const randomConjugationSetGenerator = (): ConjugationEngineRule => {
    const filteredConjugations = Conjugations.filter((c) => {
      return (
        !settingsObject ||
        ((!settingsObject.conjugations ||
          settingsObject.conjugations?.find((ct: string) => ct === c.conjugationType?.code)) &&
          (!settingsObject.tenses ||
            settingsObject.tenses?.find(
              (t: string) =>
                !c.tense ||
                t === c.tense?.code ||
                (t === Tenses.Future.code &&
                  (c.tense?.code === Tenses.FuturePresumptive.code || c.tense?.code === Tenses.FutureProbable.code))
            )) &&
          (!settingsObject.formalities ||
            settingsObject.formalities?.find((f: string) => !c.formality || f === c.formality?.code)))
      );
    });
    const length = filteredConjugations.length;
    const random = Math.floor(Math.random() * length);

    return filteredConjugations[random % length];
  };

  const findSolution = (word: Verb, conjugation: ConjugationEngineRule): Word | undefined => {
    return generateConjugations(word).conjugations.find(
      (c) =>
        c.conjugation === conjugation.conjugationType &&
        c.tense === conjugation.tense &&
        c.formality === conjugation.formality
    )?.conjugatedWord;
  };

  const generateMatchChoices = (number: number): Word[] => {
    const choices: Word[] = [];
    for (let i = 0; i < number; i++) {
      const matchConjugation = randomConjugationSetGenerator();
      const match = findSolution(randomWordGenerator(), matchConjugation);
      if (match) choices.push(match);
    }
    return choices;
  };

  const guessIsCorrect = () => {
    return guess === solution?.korean;
  };

  const makeGuess = () => {
    wordTallyUpdater(wordTally + 1);
    correctTallyUpdater(correctTally + (guessIsCorrect() ? 1 : 0));
    revealValueUpdater(true);
  };

  const nextWord = () => {
    randomWordUpdate(randomWordGenerator());
    randomConjugationUpdate(randomConjugationSetGenerator());
    revealValueUpdater(false);
    guessUpdater('');
  };

  const settingsLocalStorageUpdater = (val: Settings) => {
    if (hasWindow) window.localStorage.setItem(STORAGE_KEY, JSON.stringify(val));
    settingsUpdater(val);
  };

  const defaultSettings = React.useContext(SettingsContext).settings;
  const localStorageSettings = hasWindow ? window.localStorage.getItem(STORAGE_KEY) : null;

  const [settings, settingsUpdater] = React.useState(
    localStorageSettings ? JSON.parse(localStorageSettings) : defaultSettings
  );
  const settingsObject = settings;

  const [guess, guessUpdater] = React.useState('');
  const [revealValue, revealValueUpdater] = React.useState(false);
  const [correctTally, correctTallyUpdater] = React.useState(0);
  const [wordTally, wordTallyUpdater] = React.useState(0);
  const [randomWord, randomWordUpdate] = React.useState(randomWordGenerator());
  const [randomConjugation, randomConjugationUpdate] = React.useState(randomConjugationSetGenerator());

  const solution = findSolution(randomWord, randomConjugation);

  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }

  return (
    <SettingsProvider
      value={{
        settings,
        settingsUpdater: (settings) => {
          settingsLocalStorageUpdater(settings);
        },
      }}
    >
      <div className="pageWithTopBar">
        <div className="topbar">
          <TopBar showSettings={true} />
        </div>

        <div className="page">
          <div className={wordToGuess}>
            {' '}
            {settingsObject.showEnglish
              ? randomWord.english?.map((e, index) => (
                  <div key={index}>
                    {index + 1}. {e}
                  </div>
                ))
              : null}
          </div>
          <div>
            {settingsObject.showDictionaryForm || !settingsObject.showEnglish ? (
              <div className={dictionaryForm}>{randomWord.dictionaryForm.korean}</div>
            ) : null}
          </div>
          <div className={options}>
            {randomConjugation.conjugationType ? (
              <div className={`${tag} ${conjugation}`}>{randomConjugation.conjugationType?.label}</div>
            ) : null}
            {randomConjugation.tense ? <div className={`${tag} ${tense}`}>{randomConjugation.tense?.label}</div> : null}
            {randomConjugation.formality ? (
              <div className={`${tag} ${formality}`}>{randomConjugation.formality?.label}</div>
            ) : null}
          </div>

          {revealValue ? (
            <div className={answerContainer}>
              <div>
                <div>
                  <span>
                    You guessed: <br></br> <strong>{guess ? guess : '<no guess>'}</strong>
                  </span>
                </div>
                <br></br>
                <div>
                  {guessIsCorrect() ? (
                    <span>Good job, that was correct!</span>
                  ) : (
                    <span>
                      The correct answer was: <br></br> <strong className={answerKorean}>{solution?.korean}</strong>
                    </span>
                  )}
                </div>
              </div>
              <br></br>
              <div>
                {correctTally} right out of {wordTally}
              </div>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    nextWord();
                  }}
                >
                  Next word
                </Button>
              </div>
            </div>
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                makeGuess();
              }}
              className={guessContainer}
            >
              {settingsObject.mode === 'match' ? (
                <div></div>
              ) : (
                <div>
                  <FormControl className="full-width">
                    <InputLabel htmlFor="guess">Conjugated verb</InputLabel>
                    <Input
                      id="guess"
                      type="text"
                      onChange={(event) => {
                        guessUpdater(event.currentTarget.value);
                      }}
                    ></Input>
                  </FormControl>
                </div>
              )}
              <div className={buttons}>
                <div>
                  <Button
                    type="button"
                    onClick={() => {
                      makeGuess();
                    }}
                    variant="contained"
                  >
                    Guess
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </SettingsProvider>
  );
};

export default TrainingPage;
